/*!
 DataTables Bootstrap 4 integration
 ©2011-2017 SpryMedia Ltd - datatables.net/license
*/
var $jscomp = $jscomp || {};
$jscomp.scope = {};
$jscomp.findInternal = function (a, b, c) {
  a instanceof String && (a = String(a));
  for (var e = a.length, d = 0; d < e; d++) {
    var k = a[d];
    if (b.call(c, k, d, a)) return { i: d, v: k };
  }
  return { i: -1, v: void 0 };
};
$jscomp.ASSUME_ES5 = !1;
$jscomp.ASSUME_NO_NATIVE_MAP = !1;
$jscomp.ASSUME_NO_NATIVE_SET = !1;
$jscomp.SIMPLE_FROUND_POLYFILL = !1;
$jscomp.defineProperty =
  $jscomp.ASSUME_ES5 || 'function' == typeof Object.defineProperties
    ? Object.defineProperty
    : function (a, b, c) {
        a != Array.prototype && a != Object.prototype && (a[b] = c.value);
      };
$jscomp.getGlobal = function (a) {
  return 'undefined' != typeof window && window === a
    ? a
    : 'undefined' != typeof global && null != global
    ? global
    : a;
};
$jscomp.global = $jscomp.getGlobal(this);
$jscomp.polyfill = function (a, b, c, e) {
  if (b) {
    c = $jscomp.global;
    a = a.split('.');
    for (e = 0; e < a.length - 1; e++) {
      var d = a[e];
      d in c || (c[d] = {});
      c = c[d];
    }
    a = a[a.length - 1];
    e = c[a];
    b = b(e);
    b != e &&
      null != b &&
      $jscomp.defineProperty(c, a, {
        configurable: !0,
        writable: !0,
        value: b,
      });
  }
};
$jscomp.polyfill(
  'Array.prototype.find',
  function (a) {
    return a
      ? a
      : function (a, c) {
          return $jscomp.findInternal(this, a, c).v;
        };
  },
  'es6',
  'es3'
);
(function (a) {
  'function' === typeof define && define.amd
    ? define(['jquery', 'datatables.net'], function (b) {
        return a(b, window, document);
      })
    : 'object' === typeof exports
    ? (module.exports = function (b, c) {
        b || (b = window);
        (c && c.fn.dataTable) || (c = require('datatables.net')(b, c).$);
        return a(c, b, b.document);
      })
    : a(jQuery, window, document);
})(function (a, b, c, e) {
  var d = a.fn.dataTable;
  a.extend(!0, d.defaults, {
    dom:
      "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
    renderer: 'bootstrap',
  });
  a.extend(d.ext.classes, {
    sWrapper: 'dataTables_wrapper dt-bootstrap4',
    sFilterInput: 'form-control form-control-sm',
    sLengthSelect:
      'custom-select custom-select-sm form-control form-control-sm',
    sProcessing: 'dataTables_processing card',
    sPageButton: 'paginate_button page-item',
  });
  d.ext.renderer.pageButton.bootstrap = function (b, l, v, w, m, r) {
    var k = new d.Api(b),
      x = b.oClasses,
      n = b.oLanguage.oPaginate,
      y = b.oLanguage.oAria.paginate || {},
      g,
      h,
      t = 0,
      u = function (c, d) {
        var e,
          l = function (b) {
            b.preventDefault();
            a(b.currentTarget).hasClass('disabled') ||
              k.page() == b.data.action ||
              k.page(b.data.action).draw('page');
          };
        var q = 0;
        for (e = d.length; q < e; q++) {
          var f = d[q];
          if (a.isArray(f)) u(c, f);
          else {
            h = g = '';
            switch (f) {
              case 'ellipsis':
                g = '&#x2026;';
                h = 'disabled';
                break;
              case 'first':
                g = n.sFirst;
                h = f + (0 < m ? '' : ' disabled');
                break;
              case 'previous':
                g = n.sPrevious;
                h = f + (0 < m ? '' : ' disabled');
                break;
              case 'next':
                g = n.sNext;
                h = f + (m < r - 1 ? '' : ' disabled');
                break;
              case 'last':
                g = n.sLast;
                h = f + (m < r - 1 ? '' : ' disabled');
                break;
              default:
                (g = f + 1), (h = m === f ? 'active' : '');
            }
            if (g) {
              var p = a('<li>', {
                class: x.sPageButton + ' ' + h,
                id:
                  0 === v && 'string' === typeof f
                    ? b.sTableId + '_' + f
                    : null,
              })
                .append(
                  a('<a>', {
                    href: '#',
                    'aria-controls': b.sTableId,
                    'aria-label': y[f],
                    'data-dt-idx': t,
                    tabindex: b.iTabIndex,
                    class: 'page-link',
                  }).html(g)
                )
                .appendTo(c);
              b.oApi._fnBindAction(p, { action: f }, l);
              t++;
            }
          }
        }
      };
    try {
      var p = a(l).find(c.activeElement).data('dt-idx');
    } catch (z) {}
    u(a(l).empty().html('<ul class="pagination"/>').children('ul'), w);
    p !== e &&
      a(l)
        .find('[data-dt-idx=' + p + ']')
        .focus();
  };
  return d;
});
